import { useState } from 'react'
import style from '../nav.module.css'

const ProfileMenu = (props) => {
    const [currentStatus, setStatus] = useState('a1');
    const statusSelector = (event) => {
        if (event.currentTarget.id !== currentStatus) {
            setStatus(event.currentTarget.id)
        }
    }
    return (
        <div className={`${style['profile-menu']} profile-card`}
            style={
                props.onExpand ?
                    { width: '230px', height: '260px', padding: '20px 25px' }
                    :
                    { width: 0, height: 0, padding: '0 0' }
            }>
            {
                props.onExpand ?
                    (<>
                        <div className={style['profile-menu__nameLvl']}>
                            <h1>Kshitij Agarwal</h1>
                            <button>84</button>
                        </div>
                        <h1 className={style['profile-menu__belong']}>Majo no tabitabi</h1>
                        <div className={style['profile-menu__status']}>
                            <div>
                                <button
                                    id='a1'
                                    onClick={statusSelector}
                                    style={
                                        currentStatus === 'a1' ?
                                            {
                                                borderRadius: '50%',
                                                boxShadow: '0 0 10px rgb(157, 157, 157)',
                                                backgroundColor: 'rgb(0, 209, 0)'
                                            }
                                            :
                                            {
                                                boxShadow: '0 0 30px black',
                                                backgroundColor: 'rgb(0, 209, 0)'
                                            }
                                    }
                                ></button>
                                <h1>online</h1>
                            </div>
                            <div>
                                <button
                                    id='a2'
                                    onClick={statusSelector}
                                    style={
                                        currentStatus === 'a2' ?
                                            {
                                                borderRadius: '50%',
                                                boxShadow: '0 0 10px rgb(157, 157, 157)',
                                                backgroundColor: 'rgb(255, 183, 0)'
                                            }
                                            :
                                            {
                                                boxShadow: '0 0 30px black',
                                                backgroundColor: 'rgb(255, 183, 0)'
                                            }
                                    }
                                ></button>
                                <h1>reading</h1>
                            </div>
                            <div>
                                <button
                                    id='a3'
                                    onClick={statusSelector}
                                    style={
                                        currentStatus === 'a3' ?
                                            {
                                                borderRadius: '50%',
                                                boxShadow: '0 0 10px rgb(157, 157, 157)',
                                                backgroundColor: 'rgb(255 29 29)'
                                            }
                                            :
                                            {
                                                boxShadow: '0 0 30px black',
                                                backgroundColor: 'rgb(255 29 29)'
                                            }
                                    }
                                ></button>
                                <h1>dnd</h1>
                            </div>
                            <div>
                                <button
                                    id='a4'
                                    onClick={statusSelector}
                                    style={
                                        currentStatus === 'a4' ?
                                            {
                                                borderRadius: '50%',
                                                boxShadow: '0 0 10px rgb(157, 157, 157)',
                                                backgroundColor: 'rgb(80 80 80)'
                                            }
                                            :
                                            {
                                                boxShadow: '0 0 30px black',
                                                backgroundColor: 'rgb(80 80 80)'
                                            }
                                    }
                                ></button>
                                <h1>invisible</h1>
                            </div>
                        </div>
                        <button className={style.setting}>Settings</button>
                        <button className={style.logOut}>Log Out</button>
                    </>)
                    :
                    ''
            }
        </div >
    )
}
export default ProfileMenu