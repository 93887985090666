// import styles from "../BookCard.module.css"
import styles from "../BookCard.module.css"


const Rating = props => {
    const rated = (Number(props.rating)*10).toFixed(1);
    const ratingColors = ["#0ee41ce1","#05fc70e1", "#143bcae1", "#fc0505e1", "#fc3f05e1", "#89fc05e1","#fca605e1"]
    // console.log(ratingColors);
    // console.log(~~Number(props.rating));
    // console.log(ratingColors[Number((rated/10)%7)]);

    return (
        <div className={styles.rating} style={{boxShadow:`0 0 10px ${ratingColors[(~~Number(props.rating))%7]}`}}>
            <h1>{rated}</h1>
            <div className={styles.wave} style={{backgroundColor:ratingColors[(~~Number(props.rating))%7]}}/>
        </div>
    );
}

export default Rating;