import style from "./Quote.module.css"
import QuoteCard from "./Quote-Card/QuoteCard";
import { useState } from 'react';

const QuoteShelf = props => {
    const [translateVal, setTranslateVal] = useState(0)
    const translateHandler = (event) => {
        event.currentTarget.id === 'up' ? setTranslateVal(translateVal - 1) : setTranslateVal(translateVal + 1);
        console.log(translateVal * 693);
    }
    return (
        <div className={style['shelf']}>
            <div>
                <button disabled={translateVal === 2 ? true : false} id='down' onClick={translateHandler}><img src={require("../../resource/more icon.png")} alt="#" /></button>
            </div>
            <div
                className={style['shelf-track']}
                style={{ transform: `translateY(-${translateVal * 520}px)` }}
            >
                <QuoteCard></QuoteCard>
                <QuoteCard></QuoteCard>
                <QuoteCard></QuoteCard>
                <QuoteCard></QuoteCard>
                <QuoteCard></QuoteCard>
                <QuoteCard></QuoteCard>
            </div>
        </div >
    );
}
export default QuoteShelf;