import styles from "../BookCard.module.css"

const Details = (props) => {
    return (
        <div className={styles['manga-details']}>
            <h1 className={styles['manga-name']}>{props.Data.title}</h1>
            <h2 className={styles['mangaKa-name']}>{props.Data.authors[0].name}</h2>
            <p className={styles['manga-synopsis']}>{props.Data.synopsis}</p>
        </div>
    );
}

export default Details;