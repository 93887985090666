import style from './DetailsPage.module.css'

const MoreDetailsPage = (props) => {
    console.log("OPEN");
    return (
        <div className={style.first}>

        </div>
    );
}
export default MoreDetailsPage;