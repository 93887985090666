import { useState, useEffect } from "react";
import Profile from "./Components/Profile";
import Search from "./Components/Search";
import ThemeButton from "./Components/ThemeButton";
import style from "./nav.module.css";

const NavBar = props => {
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY > lastScrollY) {
                setShow(false);
            }
            else {
                setShow(true);
            }
            setLastScrollY(window.scrollY);
        }
    }
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    });

    const ProfileExp = (val) => {
        props.onProfileExpand(val);
    }

    return (
        <div className={`${style['nav']} ${show ? style['active'] : style['hidden']}`}>
            <ThemeButton />
            <Profile onPe={ProfileExp} />
            <Search />
        </div>
    )
}

export default NavBar;