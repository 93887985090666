import { useState } from "react";
import style from "./Carousel.module.css"
const Carousel = () => {
    const [selectedSlide, selectSlide] = useState('5');
    const [translateVal, setTranslateVal] = useState(4);
    const clickHandler = (event) => {
        console.log(event.currentTarget.id);
        switch (event.currentTarget.id) {
            default: {
                setTranslateVal(0);
                break;
            }
            case '1': {
                setTranslateVal(0);
                break;
            }
            case '2': {
                setTranslateVal(1);
                break;
            }
            case '3': {
                setTranslateVal(2);
                break;
            }
            case '4': {
                setTranslateVal(3);
                break;
            }
            case '5': {
                setTranslateVal(4);
                break;
            }
        }
        selectSlide(event.currentTarget.id);
    }
    return (
        <div className={style['carousel']}>
            <div className={style['carousel-track']} style={{ transform: `translateY(-${translateVal * 300}px)` }}>
                <div id={'1'}></div>
                <div id={'2'}></div>
                <div id={'3'}></div>
                <div id={'4'}></div>
                <div id={'5'}></div>
            </div>
            <div className={style['carousel-nav']}>
                <span
                    id={'1'}
                    onClick={clickHandler}
                    className={selectedSlide === '1' ? style['carousel-nav__selected'] : ''} />
                <span
                    id={'2'}
                    onClick={clickHandler}
                    className={selectedSlide === '2' ? style['carousel-nav__selected'] : ''} />
                <span
                    id={'3'}
                    onClick={clickHandler}
                    className={selectedSlide === '3' ? style['carousel-nav__selected'] : ''} />
                <span
                    id={'4'}
                    onClick={clickHandler}
                    className={selectedSlide === '4' ? style['carousel-nav__selected'] : ''} />
                <span
                    id={'5'}
                    onClick={clickHandler}
                    className={selectedSlide === '5' ? style['carousel-nav__selected'] : ''} />
            </div>
        </div>
    )
}

export default Carousel;