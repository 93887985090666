import { useState } from 'react';
import style from '../nav.module.css'

const ThemeButton = () => {
    const [theme, changeTheme] = useState('moon');
    const changeThemeIcon = () => {
        changeTheme(() => {
            return (theme === 'moon' ? 'sun' : 'moon');
        })
    }
    return (
        <button className={style["theme-change"]} onClick={changeThemeIcon}>
            <img
                src={require(`../../../resource/${theme}.png`)}
                alt="Theme Button" />
        </button>
    );
}

export default ThemeButton;