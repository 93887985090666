import style from './Book.module.css'
import BookBlock from './Book-Block/BookBlock';
import { useState, useEffect } from 'react';

const BookShelf = () => {
    const [content, setContent] = useState({});
    const [fetchComplete, setFetchComplete] = useState(false);
    const [fetchPage, setFetchPage] = useState(1);
    const [activeKey, setActiveKey] = useState(0);
    const fetchData = async (page) => {
        setFetchComplete(false);
        fetch(`https://api.jikan.moe/v4/top/manga?filter=upcoming&page=${(page!=undefined)?page:0}`).then(response => response.json()).then(data => {
            setContent(data.data);
            setFetchComplete(true)
        });
    }

    useEffect(() => {
        fetchData();
    }, [])

    const changePage = (e) => {
        console.log("Before fetch");
        console.log(fetchPage);
        console.log(content);
        console.log(activeKey);
        if (e.target.id === "r") {
            if (activeKey === 5) {
                fetchData(fetchPage+1);
                setFetchPage(fetchPage + 1);
                setActiveKey(0);
            }
            else setActiveKey(activeKey + 1);
        }
        else {
            if (activeKey === 0 && fetchPage === 0) {
                setFetchPage(0);
                setActiveKey(0);
            }
            else if (activeKey === 0 && fetchPage !== 0) {
                fetchData(fetchPage - 1);
                setFetchPage(fetchPage - 1);
                setActiveKey(5);
            }
            else setActiveKey(activeKey - 1);
        }
        console.log("After fetch");
        console.log(fetchPage);
        console.log(content);
        console.log(activeKey);
    }

    if (fetchComplete !== true) console.log("LOADING");
    else {
        const pages = [0, 1, 2, 3, 4, 5];
        const listItems = pages.map((num) =>
            <BookBlock key={num} pageNumber={num} Content={content} activeKey={activeKey} />
        );
        return (
            <div className={style['shelf']}>
                <div>
                    <img id="l" className={style.buttonImage} onClick={changePage} src={require("../../resource/left-arrow.png")} alt="#" />
                    <img id="r" className={style.buttonImage} onClick={changePage} src={require("../../resource/right-arrow.png")} alt="#" />
                </div>
                <div className={style['shelf-track']}>
                    {listItems}
                </div>
            </div>
        );
    }
}
export default BookShelf;