import { useState } from 'react';
import NavBar from './Navigation/NavBar';
import ProfileMenu from './Navigation/Components/ProfileMenu';
import Carousel from "./Carousel/Carousel";
import BookShelf from './Book-Shelf/BookShelf';
import QuoteShelf from './Quote-Shelf/QuoteShelf';
import style from './Site.module.css'

const Site = () => {
    const [pe, setPe] = useState(false)
    const ProfileExpand = (val) => {
        setPe(val)
    }

    return (
        <>
            <div className='header'>
                <NavBar onProfileExpand={ProfileExpand} />
                <ProfileMenu onExpand={pe} />
            </div>
            <Carousel></Carousel>
            <div className={style['shelf']}>
                <BookShelf/>
                <QuoteShelf />
            </div>
        </>
    )
}
export default Site;