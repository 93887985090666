import { useState } from "react"
import style from "../nav.module.css"

const Profile = (props) => {

    const [profileShow, setProfileShow] = useState(false);
    const profileMenu = () => {
        props.onPe(!profileShow)
        setProfileShow(!profileShow)
    }

    return (
        <img
            className={style['profile-pic']}
            src="https://i.pinimg.com/originals/03/4b/de/034bde783ea726b922100c86547831e8.jpg"
            alt="profile-pic"
            onClick={profileMenu}
        />
    )
}
export default Profile;