import style from "./QuoteCard.module.css"

const QuoteCard = props => {
    return (
        <div className={`${style['quote-body']} ${style['grad']}`}>
            <span className={style["quote-manga"]}>Manga Name</span>
            <span className={style["quote-speaker"]}>Quote Speaker</span>
            <span className={style["quote-content"]}>
                <span>L</span>orem ipsum dolor sit amet consectetur adipisicing elit. Vel commodi eos ex itaque suscipit velit culpa, quibusdam possimus dolor voluptatem quas, consequuntur distinctio, earum adipisci rerum sapiente at voluptates est!
            </span>
        </div>
    )
}

export default QuoteCard;