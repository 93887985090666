import React from "react"
import styles from "./BookCard.module.css"
import Details from "./Book-Card-Details/Details";
import Rating from "./Book-Card-Details/Rating";
import MoreDetailsPage from "../../Details-Page/MoreDetailsPage";


const BookCard = (props) => {
    const openDetailsPage=()=>{
        console.log("OPEN");
        return(<MoreDetailsPage/>)
    }
    return (
        <div onClick={openDetailsPage} className={styles['book-holder']} >
            <Rating rating={props.Data.score}></Rating>
            <img onClick={openDetailsPage} src={props.Data.images.jpg.image_url}
                alt='#'
                className={styles['poster']} />
            <Details Data={props.Data}></Details>
        </div >
    );
}

export default BookCard;