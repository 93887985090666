import React from "react";
import './CSS Reset.css';
import "./App.css"
import Site from "./Components/Site";

function App() {
  return (
    // <div className="main">
      <Site />
    // </div>
  );
}

export default App;
