import { useState, useRef } from "react";
import style from "../nav.module.css"
const Search = () => {
    const queryRef = useRef(null);
    const [active, setActive] = useState(false)
    const searchToggle = (event) => {
        event.preventDefault();
        setActive(!active)
    }
    const getQuery = () => {
        // const query = queryRef.current.value;
    }
    return (
        <form className={style['search']}>
            <input
                ref={queryRef}
                style={active ? { width: '164px', padding: '6px 15px 4px' } : { width: 0, padding: '4px 0px' }}
                type="text"
                onChange={getQuery}
                placeholder="Search"
            />
            <button
                type=""
                className={style['search-button']}
                onClick={searchToggle}
            >
                <img
                    src={require(
                        "../../../resource/search.png"
                    )}
                    alt="search icon"
                />
            </button>
        </form>
    );
};
export default Search;