// import { useState } from "react";
import styles from "./BookBlock.module.css"

import BookCard from "../Book-Card/BookCard"

const BookBlock = (props) =>{
    const contentIndex = props.pageNumber;
    return(
        <div id={contentIndex} className={`${styles.BookBlock} ${(contentIndex===props.activeKey)? `${styles.activePage}` :""}`}>
            <BookCard Data={props.Content[contentIndex*4]}></BookCard>
            <BookCard Data={props.Content[contentIndex*4+1]}></BookCard>
            <BookCard Data={props.Content[contentIndex*4+2]}></BookCard>
            <BookCard Data={props.Content[contentIndex*4+3]}></BookCard>
        </div>
    )
}
export default BookBlock;